<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
      >
        <v-skeleton-loader
          v-show="loading === true"
          type="image"
        ></v-skeleton-loader>
        <div v-show="loading === false">
          <div v-show="data.series.length === 0">
            <v-alert
              outlined
              color="warning"
              class="d-flex justify-center"
              height="250"
              text
            >
              {{ $t('NoPlotDataAlert') }}
            </v-alert>
          </div>
          <div v-show="data.series.length > 0">
            <vue-apex-charts
              :options="chartOptions"
              :series="series"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import qs from 'qs'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    dateFrom: {
      type: String,
      default: '',
    },
    dateTo: {
      type: String,
      default: '',
    },
    locations: {
      type: Array,
      default() {
        return []
      },
    },
    campaingTypes: {
      type: Array,
      default() {
        return []
      },
    },
    statuses: {
      type: Array,
      default() {
        return []
      },
    },
    advertiserId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      data: { series: [], categories: [] },
      ageData: {},
      sexData: {},
      loading: true,
      series: [],
      chartOptions: {
        chart: {
          type: 'area',
          height: 250,
        },
      },
    }
  },
  watch: {
    dateFrom() {
      this.getData()
    },
    dateTo() {
      this.getData()
    },
    advertiserId() {
      this.getData()
    },
    locations() {
      this.getData()
    },
    campaingTypes() {
      this.getData()
    },
    statuses() {
      this.getData()
    },
  },

  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      const dateStart = this.dateFrom != null ? `${this.dateFrom}` : '1970-01-01'
      // eslint-disable-next-line operator-linebreak
      const dateFinish = this.dateTo != null ? `${this.dateTo}` : `${new Date().toISOString().substr(0, 10)}`

      const params = {
        params: {
          dateFrom: dateStart,
          dateTo: dateFinish,
          userId: this.$getUserId(),
          advertiserId: this.advertiserId,
          statuses: this.statuses,
          locations: this.locations,
          campaing_types: this.campaingTypes,
        },
        paramsSerializer: param => qs.stringify(param, { arrayFormat: 'repeat' }),
      }

      // this.$http.get('/campaigns/widgets/avtivity', params).then(response => {
      // this.$http.get(`${Vue.prototype.$apiBaseURL}/campaign-report/advertiser-daily?` + qs.stringify(params, { arrayFormat: 'repeat' })).then(response => {
      this.$http.get(`${this.$apiBaseURL}/campaigns/activity`, params).then(response => {
        // eslint-disable-next-line no-unused-vars
        const tempData = response.data
        if (tempData !== null) {
          const views = []
          const clicks = []
          const uniqViews = []
          this.data.categories = []
          this.data.series = []
          tempData.forEach(element => {
            views.push(element.views != null ? element.views : 0)
            clicks.push(element.clicks != null ? element.clicks : 0)
            uniqViews.push(element.uniqViews != null ? element.uniqViews : 0)
            this.data.categories.push(
              element.statisticsDate != null
                ? `${new Date(element.statisticsDate).getDate()}-${new Date(element.statisticsDate).getMonth()}`
                : null,
            )
          })
          this.data.series.push({ name: this.$t('Shows'), data: views })
          this.data.series.push({ name: this.$t('References'), data: clicks })
          this.data.series.push({ name: this.$t('Сoverage'), data: uniqViews })
        } else {
          this.data = { series: [], categories: [] }
        }
        this.series = this.data.series
        this.chartOptions = {
          chart: {
            toolbar: {
              show: false,
            },
            height: 350,
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            curve: 'smooth',
          },
          legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'center',
            fontSize: '14px',
            fontFamily: '"Inter", sans-serif',
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          xaxis: {
            categories: this.data.categories,
          },
          yaxis: {
            // opposite: isRtl
          },
          fill: {
            opacity: 0.4,
            type: 'solid',
          },
          tooltip: {
            shared: false,
          },
          colors: ['#e0cffe', '#b992fe', '#ab7efd'],
        }
        this.loading = false
      })
    },
  },
}
</script>
<style lang="css">
.apexcharts-toolbar {
  display: none;
}
</style>
