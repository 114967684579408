<template>
  <div>
    <v-tabs vertical>
      <v-tab>
        {{ $t('Country') }}
      </v-tab>
      <v-tab>
        {{ $t('City') }}
      </v-tab>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <country-component
              :date-from="dateFrom"
              :date-to="dateTo"
              :advertiser-id="advertiserId"
              :locations="locations"
              :campaing-types="campaingTypes"
              :statuses="statuses"
            ></country-component>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <city-component
              :date-from="dateFrom"
              :date-to="dateTo"
              :advertiser-id="advertiserId"
              :locations="locations"
              :campaing-types="campaingTypes"
              :statuses="statuses"
            ></city-component>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import CountryComponent from './GeoWidgetComponents/CountryComponent.vue'
import CityComponent from './GeoWidgetComponents/CityComponent.vue'

export default {
  components: {
    CountryComponent,
    CityComponent,
  },
  props: {
    dateFrom: {
      type: String,
      default: '',
    },
    dateTo: {
      type: String,
      default: '',
    },
    advertiserId: {
      type: Number,
      default: 0,
    },
    locations: {
      type: Array,
      default() {
        return []
      },
    },
    campaingTypes: {
      type: Array,
      default() {
        return []
      },
    },
    statuses: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      search: '',
      calories: '',
      data: [],
      loading: true,
    }
  },

  mounted() {},
  methods: {
    getData() {
      // this.loading = true
      // const dateStart = this.dateFrom != null ? Math.floor(new Date(this.dateFrom).getTime() / 1000) : 0
      // // eslint-disable-next-line operator-linebreak
      // const dateFinish =
      //   this.dateTo != null
      //     ? Math.floor(new Date(this.dateTo).getTime() / 1000)
      //     : Math.floor(new Date().getTime() / 1000)
      // const params = {
      //   params: {
      //     date_from: dateStart,
      //     date_to: dateFinish,
      //     advertisers: this.$getUserAdvertisers(),
      //   },
      // }
      // this.$http.get('/advertisers/widgets/total', params).then(response => {
      //   this.data = response.data
      //   this.loading = false
      // })
    },
  },
}
</script>
